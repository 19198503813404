/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-17 15:38:35
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-17 15:40:01
 */
import React, { useState, useEffect } from "react"
import "./index.css"
import Slider from "react-slick"
import "antd/lib/icon/style"
import { navigate } from "gatsby"
import {useGTM} from '@/hooks/useGTM.js'

const HeaderSwiper = ({ data }) => {
    const [swiperBg, setSwiperBg] = useState(0)

    let arrowLeft = document.querySelector('.arlabHeaderSwiper .slick-prev') 
   
    let arrowRight =document.querySelector('.arlabHeaderSwiper .slick-next') 

    if(!!arrowLeft){
        arrowLeft.innerHTML = ''
    }
    if(!!arrowRight){
        arrowRight.innerHTML = ''
    }

    const goDetail = (data) => {
        let appName = data.name.split(' ').join('')
        navigate(`/arlab/detail?id=${data.id}&appname=${appName}`, {
            state: {
                params: data
            }
        })
        useGTM('arLab_click','',data.name,'top banner')
    }

    const settings = {
        initialSlide: 0,
        arrows: true,
        dots: true,
        infinite: true,
        className: 'arlabSwiper',
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        // mousewheel: true,
        autoplay: true,
        touchMove: true,
        dotsClass: "adaLabSwiper-dots",
        customPaging: function (i) {
            return <a></a>;
        },
        afterChange: function (i) {
            setSwiperBg(i)
        }
    }
    return (
        <div className='arlabHeaderSwiper' style={{ background: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)) ,url(${data[swiperBg]?.poster}) ` }}>
            <div className='swiper-left'></div>
            <div className='swiper-right'></div>
            <div className='swiper-bottom'></div>

            <Slider {...settings}>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <div style={{ backgroundImage: `url(${item.poster}) ` }} className='arlabPicBox'
                                onClick={() => { goDetail(item) }}
                            ></div>
                        </div>)

                })}

            </Slider>

        </div>

    )
}
export default HeaderSwiper