/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-18 20:59:17
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-18 21:02:14
 */
import styled from "styled-components";
export const Featured = styled.div`
    width: 79px;
    height: 22px;
    position:absolute ;
    left: 20px;
    top: 16px;
    z-index:1;
    padding: 2px 12px;
    text-align:center ;
    background: #FF3300;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    font-weight: 600;
    font-size: 12px;    
    color: #FFFFFF;
    transform: translateZ(100px);
    -webkit-transform: translateZ(100px);
    transition:0.3s;

`