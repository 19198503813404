/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-18 15:48:52
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-18 22:04:26
 */
import React from 'react'
import { navigate } from "gatsby"
import style from "./index.module.less"
import pic4 from "@/images/image_dots.svg"
import { supportIcon } from "@/images/arlab/index"
import { Featured } from "./style.js"
import { useIntl } from 'react-intl'
import useNavigate from '@/hooks/useNavigate'
import {useGTM} from '@/hooks/useGTM.js'

const Index = props => {
    const { data , type } = props
    const intl = useIntl()
    const goDetail = (data) => {
        let appName = data.name.split(' ').join('')
        useNavigate(`/arlab/detail?id=${data.id}&appname=${appName}`,false, {
            state: {
                params: data
            }
        })
        useGTM('arLab_click','',data.name,'bottom list')

    }



    return (
        <div className={style.cards}>
            <div className={style.cardsList}>
                {data.map((item, index) => {

                    return (<div className={style.cardsListItem} onClick={() => {!!type&&type=='mac'?useNavigate(item.link): goDetail(item) }} key={`arlabCard${index}`}>

                        <div className={style.cardsListItemTop}
                        >
                            {item.tags.indexOf("Featured") > -1 ? <Featured>{intl.formatMessage({ id: "arlab_featured", defaultMessage: "Featured" })}</Featured> : ""}
                            <div className={style.posterItem} style={{ backgroundImage: `url(${item.poster})` }}></div>

                            <div className={style.mask} style={{ backgroundImage: `url(${pic4})` }}></div>
                        </div>
                        <div className={style.cardsListItemBot}>
                            <div className={style.name}>{item.name}</div>
                            <div className={style.tag}>
                                {item.supportDevice.indexOf("Air") > -1 ? <span style={{ marginRight: "10px" }}>Air<img src={supportIcon} /></span> : ""}
                                {item.supportDevice.indexOf("Light") > -1 ? <span>Light<img src={supportIcon} /></span> : ""}
                            </div>
                        </div>
                    </div>)
                })}
                {data.length >1 && <> 
                    <div className={style.blankdiv}></div>
                    <div className={style.blankdiv}></div>
                    <div className={style.blankdiv}></div>
                    <div className={style.blankdiv}></div>
                    <div className={style.blankdiv}></div>
                    <div className={style.blankdiv}></div>
                </>}

            </div>
        </div>
    )
}

export default Index

